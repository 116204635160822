<template lang="pug">
  v-app
    .d-flex.h-100(:class="mobileView ? 'flex-column' : 'flex-row'")
      v-card(
        class="ml-auto p-2 mom-card"
        style="border-radius: 20px"
      )
        v-card-title.mb-3
          h3(style="color: black")
            b {{ $t("mom.info") }}
          v-spacer
          v-icon(
            @click="reset"
            color="primary"
            v-bind="size"
          ) mdi-close-box
        v-card-text 
          v-form(
            ref="mom"
            v-model="valid"
            lazy-validation
          )
            label(for="mothername") {{ $t('mom.mothername') }}
            v-text-field(
              name="mothername"
              outlined
              color="primary"
              v-model.trim="motherName"
              :rules="nameRules"
            )
            label(for="motherage") {{ $t('mom.motherage') }}
            v-text-field(
              name="motherage"
              outlined
              v-model="motherAge"
              type="number"
              :rules="ageRules"
            )
            label(for="email") {{ $t('mom.email') }}
            v-text-field(
              name="email"
              outlined
              v-model.trim="email"
              :rules="emailRules"
            )
            label(for="daughtername") {{ $t('mom.daughtername') }}
            v-text-field(
              name="daughtername"
              outlined
              v-model.trim="daughterName"
              :rules="daughterRules"
            )
        v-card-actions
          v-btn(
            class="m-auto mb-4"
            color="primary"
            @click="save"
            v-bind="size"
          ) 
            b {{ $t("mom.submit") }}
      img.image(class="mr-auto" src="@/assets/images/mom/mom_1.png")
    v-dialog(
      content-class="survey-dialog"
      v-model="dialogSurvey"
      max-width="1000px"
    )
      Survey(
        :loading="loading"
        @submit="saveSurvey($event)"
      )
    v-dialog(
      v-model="dialogComplete"
      max-width="1000px"
    )
      v-card.py-4
        v-card-text.d-flex
          img(class="m-auto" src="@/assets/images/mom/soal-submited.png")
        v-card-actions
          v-btn(
            color="primary"
            @click="close"
            v-bind="size"
          )
            b {{ $t("mom.close") }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";
import Survey from "./Survey";

export default {
  components: {
    Survey
  },
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    }
  },
  data() {
    return {
      dialogSurvey: false,
      dialogComplete: false,
      valid: false,
      nameRules: [v => !!v || this.$t("mom.name_required")],
      ageRules: [
        v => !!v || this.$t("mom.age_required"),
        v => v > 0 || this.$t("mom.age_format")
      ],
      emailRules: [
        v => !!v || this.$t("mom.email_required"),
        v => /.+@.+\..+/.test(v) || this.$t("mom.email_format")
      ],
      daughterRules: [v => !!v || this.$t("mom.daughter_required")],
      motherName: "",
      motherAge: null,
      daughterName: "",
      email: "",
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  methods: {
    reset() {
      this.$refs.mom.reset();
    },
    save() {
      if (!this.$refs.mom.validate()) return;
      this.dialogSurvey = true;
    },
    saveSurvey(e) {
      if (!this.$refs.mom.validate()) return;

      let url = config.saveMomUrl();

      var json = {
        name: this.motherName,
        age: this.motherAge,
        email: this.email,
        kidName: this.daughterName,
        locale: this.$store.getters.currentLocale,
        data: e
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.dialogSurvey = false;

              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.dialogComplete = true;
              } else {
                this.$swal(
                  this.$t("error.save_mom"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.save_mom"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.save_mom"),
            response.body.error.message,
            "error"
          );
        });
    },
    close() {
      this.dialogComplete = false;
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-up')}
  .v-application
    height: 82vh !important
    overflow: hidden !important

.mom-card
  margin: auto 0
  width: 35%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 80%
    margin: auto

    .v-card__title
      h3
        font-size: 20px
    label
      font-size: 14px

.v-btn
  // margin-top: rem !important
  // max-width: 160px !important
  margin: auto

.image
  margin: auto 0
  width: 42%
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 80%
    margin: auto
</style>
