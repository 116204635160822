<template lang="pug">
  v-card
    v-card-text
      v-list(dense)
        div(
          v-for="(i, index1) in $t('mom.survey.questions')"
          :key="index1"
        )
          div(
            class="mb-2"
            style="font-size: 15px; font-weight: bold"
          )
            b.mr-2 {{ index1 + 1 }}.
            span(v-html="i.text")
          .d-flex(:class="index1 == 1 || index1 == 3 ? 'flex-row flex-wrap' : 'flex-column'")
            .p-1(
              style="cursor: pointer; font-size: 14px"
              v-for="(j, index2) in i.answers" 
              :key="index1+index2"
            )
              .d-flex.flex-row
                v-checkbox(
                  v-model="answer[index1+1]"
                  :value="j.value"
                )
                .my-auto(
                  v-html="j.text"
                  style="color: black;"
                )
                template(v-if="j.extra")
                  v-text-field(
                    class="ml-2"
                    v-model="extra[index1+1]"
                  )
    v-card-actions
      v-btn(
        class="m-auto mb-4"
        v-bind="size"
        color="primary"
        @click="saveSurvey"
      )
        b {{ $t("mom.submit2") }}
        i.fas.fa-spin.fa-spinner.ml-2(v-if="loading")
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  computed: {
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    }
  },
  data() {
    return {
      answer: {
        1: "",
        2: [],
        3: "",
        4: [],
        5: "",
        6: "",
        7: ""
      },
      extra: {
        2: "",
        3: "",
        4: ""
      }
    };
  },
  methods: {
    saveSurvey() {
      var json = {
        1: this.answer[1],
        2: this.extra[2]
          ? this.answer[2].join(",").replace("lain", this.extra[2])
          : this.answer[2].join(","),
        3: this.extra[3] || this.answer[3],
        4: this.extra[4]
          ? this.answer[4].join(",").replace("lain", this.extra[4])
          : this.answer[4].join(","),
        5: this.answer[5],
        6: this.answer[6],
        7: this.answer[7]
      };

      console.log(json);

      var check = true;

      Object.values(json).forEach(x => {
        if (x == "" || x == "lain") {
          this.$swal(
            this.$t("error.mom_survey"),
            this.$t("error.mom_survey_incomplete"),
            "error"
          );
          check = false;
          return;
        }
      });

      if (check) this.$emit("submit", json);
    }
  }
};
</script>

<style lang="sass" scoped></style>
